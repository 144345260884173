import { LocaleCodes } from '../constants/localization.constants'

export const mapLocale = (localeOfEntry: string) => {
  let pageLocale, isLocalized
  if (localeOfEntry && localeOfEntry !== LocaleCodes.ENGLISH.name) {
    for (let locale in LocaleCodes) {
      if (LocaleCodes[locale].name === localeOfEntry) {
        isLocalized = true
        pageLocale = LocaleCodes[locale].code
      }
    }
  } else {
    // Default to English if no locale is selected
    pageLocale = LocaleCodes.ENGLISH.code
  }
  return { pageLocale, isLocalized }
}
