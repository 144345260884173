import axios from 'axios'

class GoogleCustomSearch {
  private apiKey: string
  private searchEngine: string
  public searchURL: string
  public keywords: string
  public website: string
  public numberOfResults: number
  public startFrom: number
  public language: string

  constructor(
    keywords: string,
    website: string,
    language: string,
    startFrom: number = 1,
    numberOfResults: number = 10
  ) {
    this.apiKey = process.env.GATSBY_GOOGLE_CUSTOM_SEARCH_API_KEY as string //cast to string
    this.searchEngine = process.env.GATSBY_GOOGLE_CUSTOM_SEARCH_ENGINE_ID as string
    this.searchURL = process.env.GATSBY_GOOGLE_CUSTOM_SEARCH_URL as string
    this.keywords = keywords
    this.website = website
    this.language = language
    this.numberOfResults = numberOfResults
    this.startFrom = startFrom
  }

  async fetchResults() {
    const fromWebsite =
      this.website && this.website !== 'all'
        ? `&siteSearch=${this.website}`
        : ''

    const searchURL = `${this.searchURL}?key=${this.apiKey}&cx=${this.searchEngine}&num=${this.numberOfResults}&start=${this.startFrom}${fromWebsite}&q=${this.language} `

    let searchResults: any = {
      totalResults: 0,
      searchResults: [],
    }

    try {
      let results: any

      if (this.keywords && this.keywords.trim().length >= 3) {
        results = await axios.get(searchURL + this.keywords)
      }

      if (
        results &&
        results.data &&
        results.data.items &&
        results.data.items.length > 0
      ) {
        searchResults = {
          totalResults: results.data.searchInformation.totalResults,
          searchResults: results.data.items,
        }
      } else {
        searchResults = {
          totalResults: 0,
          searchResults: [],
        }
      }
    } catch (error) {
      console.log('Something went wrong:', error.message)
    }

    return searchResults
  }
}

export default GoogleCustomSearch
