import React, { useState, useEffect, useRef } from 'react'
import GoogleCustomSearch from '../../../integrations/googleCustomSearch'
import Pagination from '../../global/pagination'
import UrlUtil from '../../../utils/url.utils'
import { SearchListing } from './style'

const Search = ({ open, handleSearchClick }) => {
  const [keywords, setKeywords] = useState('')
  const [totalResults, setTotalResults] = useState(0)
  const [showFilters, setShowFilters] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('all')
  const [searchResults, setSearchResults] = useState([])
  const [selectedPage, setSelectedPage] = useState(1)
  const [showNoResultsFound, setShowNoResultsFound] = useState(false)
  const close = useRef(null)
  const searchListing = useRef(null)
  const NUM_RESULTS = 6

  const resetAll = () => {
    setKeywords('')
    setTotalResults(0)
    setShowFilters(false)
    setSearchResults([])
    setSelectedPage(1)
    setShowNoResultsFound(false)
    setSelectedFilter('all')
    history.pushState(
      null,
      null,
      UrlUtil.removeURLParameter(window.location.href, 'q')
    )
  }

  const handleInputTextChange = (e: any) => {
    setKeywords(e.target.value)
  }

  const setSearchWebsite = (filterText: string) => {
    let searchOnWebsite: string

    if (filterText === 'uipath.com') {
      setSelectedFilter('www.uipath.com')
      searchOnWebsite = 'www.uipath.com'
    } else if (filterText === 'forum') {
      setSelectedFilter('forum.uipath.com')
      searchOnWebsite = 'forum.uipath.com'
    } else if (filterText === 'docs') {
      setSelectedFilter('docs.uipath.com')
      searchOnWebsite = 'docs.uipath.com'
    } else {
      setSelectedFilter('all')
      searchOnWebsite = 'all'
    }

    return searchOnWebsite
  }

  const setSearchLanguage = () => {
    const urlPath: any = window.location.pathname.split('/')
    let searchWebsites: string

    if (urlPath && urlPath.length > 1) {
      switch (urlPath[1]) {
        case 'de':
          searchWebsites =
            'site:uipath.com/de/ OR site:forum.uipath.com OR site:docs.uipath.com/*/lang-de/ OR site:docs.uipath.com/lang-de/'
          break
        case 'fr':
          searchWebsites =
            'site:uipath.com/fr/ OR site:forum.uipath.com OR site:docs.uipath.com/*/lang-fr/ OR site:docs.uipath.com/lang-fr/'
          break
        case 'pt':
          searchWebsites =
            'site:uipath.com/pt/ OR site:forum.uipath.com OR site:docs.uipath.com'
          break
        case 'es':
          searchWebsites =
            'site:uipath.com/es/ OR site:forum.uipath.com OR site:docs.uipath.com'
          break
        case 'ja':
          searchWebsites =
            'site:uipath.com/ja/ OR site:forum.uipath.com OR site:docs.uipath.com/*/lang-ja/ OR site:docs.uipath.com/lang-ja/'
          break
        case 'ko':
          searchWebsites =
            'site:uipath.com/ko/ OR site:forum.uipath.com OR site:docs.uipath.com'
          break
        default:
          searchWebsites =
            'site:uipath.com OR site:forum.uipath.com OR site:docs.uipath.com'
      }
    } else {
      searchWebsites =
        'site:uipath.com OR site:forum.uipath.com OR site:docs.uipath.com'
    }

    return searchWebsites
  }

  const handleSearchItemClick = (url: string) => {
    window.location.href = url ? url : window.location.href
  }

  const handleSearchFromUrlQueryParameter = async () => {
    const searchParameter: string = UrlUtil.getSearchKeywords('q')
      ? UrlUtil.getSearchKeywords('q')
      : ''
    if (searchParameter) {
      setKeywords(searchParameter)
      try {
        const result: any = await new GoogleCustomSearch(
          searchParameter,
          selectedFilter,
          setSearchLanguage(),
          1,
          NUM_RESULTS
        ).fetchResults()
        if (result.totalResults > 0) {
          setTotalResults(result.totalResults)
          setSearchResults(result.searchResults)
          setSelectedPage(1)
          setShowFilters(true)
          setShowNoResultsFound(false)
        } else {
          setTotalResults(0)
          setSearchResults([])
          setShowNoResultsFound(true)
        }
      } catch (error) {
        console.log('Something went wrong:', error.message)
      }
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (keywords.length >= 3) {
      const searchInput = encodeURIComponent(keywords).split('%20').join('+')
      history.pushState(
        null,
        null,
        UrlUtil.updateURLParameter(window.location.href, 'q', searchInput)
      )
      setTotalResults(0)
      setSearchResults([])
      setShowNoResultsFound(false)

      try {
        const result: any = await new GoogleCustomSearch(
          keywords,
          selectedFilter,
          setSearchLanguage(),
          1,
          NUM_RESULTS
        ).fetchResults()
        setSelectedFilter('all')
        if (result.totalResults > 0) {
          setTotalResults(result.totalResults)
          setSearchResults(result.searchResults)
          setShowFilters(true)
          setSelectedPage(1)
        } else {
          setTotalResults(0)
          setSearchResults([])
          setShowNoResultsFound(true)
        }
      } catch (error) {
        console.log('Something went wrong:', error.message)
      }
    } else {
      setTotalResults(0)
      setSearchResults([])
      setShowFilters(false)
      setShowNoResultsFound(false)
      history.pushState(
        null,
        null,
        UrlUtil.removeURLParameter(window.location.href, 'q')
      )
    }
  }

  const handleFiltersClick = async (e: any) => {
    if (e.target.textContent) {
      setTotalResults(0)
      setSearchResults([])
      setShowNoResultsFound(false)
      try {
        const result: any = await new GoogleCustomSearch(
          keywords,
          setSearchWebsite(e.target.textContent.toLowerCase()),
          setSearchLanguage(),
          1,
          NUM_RESULTS
        ).fetchResults()
        if (result.totalResults > 0) {
          setTotalResults(result.totalResults)
          setSearchResults(result.searchResults)
          setSelectedPage(1)
          setShowFilters(true)
          setShowNoResultsFound(false)
        } else {
          setTotalResults(0)
          setSearchResults([])
          setShowNoResultsFound(true)
        }
      } catch (error) {
        console.log('Something went wrong:', error.message)
      }
    }
  }

  const handleCloseClick = (e) => {
    handleSearchClick(e)
    resetAll()
  }

  const clearInput = () => {
    setKeywords('')
    resetAll()
  }

  const handlePaginateClick = async (page: number) => {
    setSelectedPage(page)
    setTotalResults(0)
    setSearchResults([])
    setShowNoResultsFound(false)
    try {
      const result: any = await new GoogleCustomSearch(
        keywords,
        selectedFilter,
        setSearchLanguage(),
        page ? (page - 1) * NUM_RESULTS + 1 : 1,
        6
      ).fetchResults()
      searchListing.current.scrollTo({ top: 0, behavior: 'smooth' })
      if (result.totalResults > 0) {
        setTotalResults(result.totalResults)
        setSearchResults(result.searchResults)
        setShowFilters(true)
        setShowNoResultsFound(false)
      } else {
        setTotalResults(0)
        setSearchResults([])
        setShowNoResultsFound(true)
      }
    } catch (error) {
      console.log('Something went wrong:', error.message)
    }
  }

  const toggle = (e) => {
    let isContainer = e.target.closest('.SearchListing')
    if (!isContainer && open) {
      close.current.click()
    }
  }

  useEffect(() => {
    handleSearchFromUrlQueryParameter()
    if (open) window.addEventListener('click', toggle, false)
    return () => {
      return window.removeEventListener('click', toggle, false)
    }
  }, [open])

  return (
    <SearchListing
      ref={searchListing}
      data-cy="SearchListing"
      className={`SearchListing ${open ? 'visible' : ''}`}
    >
      <div className="gcs__searchContainer">
        <div className="gcs__searchInputContainer">
          <div className="gcs__searchInputTextBoxContainer">
            <form onSubmit={handleSubmit}>
              <input
                value={keywords}
                onChange={handleInputTextChange}
                id="gcs__input"
                placeholder="Search here... (minimum 3 characters)"
              />
              <i
                className={`gcs__clearInput ${
                  keywords.length > 0 ? `gcs__clearInput--active` : ''
                }`}
                onClick={clearInput}
              ></i>
            </form>
          </div>
          <button className="gcs__searchBtn" onClick={handleSubmit}>
            Search
          </button>
        </div>
        <div
          className={`gcs__tabsContainer ${
            showFilters ? `gcs__tabsContainer--active` : ''
          }`}
        >
          <div
            className={`gcs__tab ${
              selectedFilter === 'all' ? 'gcs__tab--active' : ''
            }`}
            onClick={handleFiltersClick}
            data-cy="GCSTabs--All"
          >
            All
          </div>
          <div
            className={`gcs__tab ${
              selectedFilter === 'www.uipath.com' ? 'gcs__tab--active' : ''
            }`}
            onClick={handleFiltersClick}
            data-cy="GCSTabs--uipath"
          >
            uipath.com
          </div>
          <div
            className={`gcs__tab ${
              selectedFilter === 'forum.uipath.com' ? 'gcs__tab--active' : ''
            }`}
            onClick={handleFiltersClick}
            data-cy="GCSTabs--forums"
          >
            Forum
          </div>
          <div
            className={`gcs__tab ${
              selectedFilter === 'docs.uipath.com' ? 'gcs__tab--active' : ''
            }`}
            data-cy="GCSTabs--docs"
            onClick={handleFiltersClick}
          >
            Docs
          </div>
        </div>
        <div
          className={`gcs__resultsContainer ${
            searchResults.length ? `gcs__resultsContainer--active` : ''
          }`}
        >
          {searchResults &&
            searchResults.map((item, index) => {
              return (
                <div
                  className="gcs__searchItem"
                  onClick={() => handleSearchItemClick(item.link)}
                  key={index}
                >
                  <h4
                    className="gcs__searchItemTitle"
                    dangerouslySetInnerHTML={{ __html: item.htmlTitle }}
                  ></h4>
                  <div className="gcs__searchItemLinkContainer">
                    <a
                      className="gcs__searchItemLink"
                      href={item.link}
                      target="_blank"
                      dangerouslySetInnerHTML={{
                        __html: item.htmlFormattedUrl,
                      }}
                    ></a>
                  </div>
                  <p
                    className="gcs__searchItemDescription"
                    dangerouslySetInnerHTML={{
                      __html: item.htmlSnippet,
                    }}
                  ></p>
                  <div className="gcs__searchItemLabelContainer">
                    <span className="gcs__searchItemLabel">
                      {item.labels[0].displayName === 'www'
                        ? 'uipath.com'
                        : item.labels[0].displayName}
                    </span>
                  </div>
                </div>
              )
            })}
          <div
            className={`gcs__searchPagination ${
              totalResults > 10 ? 'gcs__searchPagination--active' : ''
            }`}
          >
            <Pagination
              activePage={selectedPage}
              handleClick={handlePaginateClick}
              numResults={totalResults >= 100 ? 91 : totalResults}
              numPagesBeforeEllipses={3}
              numResultsPerPage={NUM_RESULTS}
              showPageNumbers={false}
              customNextText="Next"
              customPreviousText="Prev"
            />
          </div>
        </div>
      </div>

      <i id="gcs__Close" ref={close} onClick={handleCloseClick}>
        Close
      </i>
    </SearchListing>
  )
}

export default Search
